
import { defineComponent } from 'vue';
import { WorkController, ProjectController, TimeEntryController, SessionController, ClientController, DepartmentController } from '@/controller';
import { List, ListItem, UiTabs, UiTab, IconButton, Button, Switch } from '@/ui';
import { User, Project, Employee, Work, Company, Department, Client, Workstation } from '@/model';
import store from '@/store/index';

export default defineComponent({
    name: "ComAddTimeEntry",
    components: { List, ListItem, UiTabs, UiTab, IconButton, Button, Switch },
    created(){
        this.getSessionUser()
        this.getDepartments()
    },
    props: {
        employee: {
            type: Employee,
            default: null
        },
        projects: {
            type: Array,
            default: [] as Array<Project>
        },
        clients: {
            type: Array,
            default: [] as Array<Client>
        },
        works: {
            type: Array,
            default: [] as Array<Work>
        },
        workstation: {
            type: Object,
            default: {} as Workstation
        }
    },
    data(){
        return{
            store,
            //projects: [] as Array<Project>,
            //clients: [] as Array<any>,
            //works: [] as Array<Work>,
            departments: [] as Array<Department>,
            form:{
                projectId: 0,
                customerId: 0,
                workId: 0,
                workstationId: 0,
                employeeId: 0,
            },
            activeTab: 0,
            //sessionUser: {} as User,
            //sessionEmployee: {} as Employee,
            sessionCompany: {} as Company,
            showAllWorks: false,
            showAllProjects: false
        }
    },
    computed:{
        filteredProjectList(): Project[] {
            if (this.projects) {
                let projectList : any = this.projects

                if (!this.showAllProjects) {
                    //projectList.log('filteredProjectList', projectList)
                    //projectList = this.projects.filter(project => work.getDepartments().some(dep => this.employeeDepartments.find(empDep => empDep.uid == dep.uid)))
                    projectList = this.defaultProject ? [...[this.defaultProject], ...this.employeeProjects] : [...this.employeeProjects]
                    //console.log('filteredProjectList', projectList)
                } else {
                    projectList = [...this.projects]
                }

                return projectList as Array<Project>
            }
            return []
        },
        filteredWorkList(): Work[] {
            if (this.works) {
                
                let workList : any = this.works

                if (!this.showAllWorks) {
                    if (this.workstation.works) {
                        workList = this.workstation.works
                    } else {
                        workList = (this.works as Array<Work>).filter(work => work.getDepartments().some(dep => this.employeeDepartments.find(empDep => empDep.uid == dep.uid)))
                    }
                }
                return workList
            }
            return []
        },
        defaultProject(): Project {
                //cast to avoid error message
            return (this.projects as Array<Project>).find((project: Project) => project.getUid() == this.sessionCompany?.settings?.defaultProject) as Project
        }, 
        employeeProjects(): Array<Project> {
                //cast to avoid error message
            return (this.projects as Array<Project>).filter((project: Project) => this.employee.activeProjects.find((active: Project) => active.uid == project.uid) != null)
        },
        employeeDepartments(): Array<Department> {
            return this.departments.filter((dep: Department) => this.employee.departments.find((empDep: Department) => empDep.uid == dep.uid) != null)
        }
    },
    mounted() {
        this.form.employeeId = this.employee?.uid
    },
    methods: {
        onCancel() {
            //this.$router.go(-1)
            this.resetForm() 
            this.$emit('cancel');
        },
        onSelectProject(item:any){
            this.form.projectId = item.uid
            this.form.customerId = item.customer.uid
            this.switchTab(+1)
        },
        onSelectWork(item:any){
            this.form.workId = item.uid
            this.switchTab(+1)
        },
        onSwitchTab(index: number) {
            this.switchTab(index)
        },
        switchTab(index: number) {
            this.activeTab += index
            if(this.activeTab==2) {
                this.handleCreateTimeEntry()
            } else {
                this.$emit('tabChange', this.activeTab)
            }
        },
        onSetDefaultProject() {
            if (this.sessionCompany?.settings?.defaultProject != 0) {
                this.form.projectId = this.sessionCompany?.settings?.defaultProject as number
                this.switchTab(+1)
            }
        },
        onToggleWorks() {
            this.showAllWorks = !this.showAllWorks
        },
        onToggleProjects() {
            this.showAllProjects = !this.showAllProjects
        },
            //functions
        resetForm() {
            this.form.projectId = 0
            this.form.customerId = 0
            this.form.workId = 0
            this.form.workstationId = 0
            this.form.employeeId = 0   
        },
        async getSessionUser(){
            //this.sessionUser = SessionController.sessionUser
            //this.sessionEmployee = SessionController.sessionEmployee
            this.sessionCompany = SessionController.sessionCompany
            //this.form.employeeId = this.sessionEmployee ? this.sessionEmployee.getUid() : 0
            
            //this.getProjects()
            //this.getClients()
            //this.getWorks()
        },         
        /* async getProjects(){
            this.projects = (await ProjectController.fetchProjects()).items;
        }, */
        /* async getClients(){
            this.clients = await ClientController.fetchClients()
        }, */
        /* async getWorks(){
            const res = await WorkController.fetchWorks()
            this.works = res.items ? res.items : []
        },  */ 
        async getDepartments(){
            const res = await DepartmentController.fetchDepartments()
            this.departments = (res.items ? res.items : [])
        },        
        async handleCreateTimeEntry(){
            const timeentries = await TimeEntryController.createTimeEntry(this.form.employeeId, this.form.workId, this.form.projectId, this.form.customerId, this.form.workstationId)
            this.resetForm()
            this.$emit('created', timeentries)
        },
    },
})
