const AxiosErrorService = { 

    handleError(error: any) {
      //console.log('AxiosErrorService error:', error);
      if (error.response) {
          return {
              'error': {
                  'status': error.response.status,
                  'data': error.response.data,
                  'message': error.message
              }
          };
      } else {
          return {
              'error': {
                  'status': '500',
                  'data': 'http error'
              }
          };
      }
    }
  
  }
  
  export default AxiosErrorService;