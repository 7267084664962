
import store from "@/store";
import { IconButton, Button, Table, Card } from "@/ui";
import { defineComponent } from "vue";
import { EmployeeController, UpdateController, WorkstationController, SessionController } from "@/controller/";
import { Employee, Workstation } from "@/model/index.ts";

export default defineComponent({
    name: "ChipForm",
    components: { IconButton, Button, Table, Card },
    created(){
        this.adminEmployee = null
        UpdateController.setOnNfcUpdate(this.onNfcChipId)
            //TODO: for testing only
        /* this.adminEmployee = SessionController.sessionEmployee
        this.getEmployees()
        this.getWorkstations() */
    },
    data(){
        return{
            store,
            employeeTableData:{
                thFields: [
                    { title: this.$t("employee.number"), property: "number", type: "string", clickAction: "editEmployee" }, 
                    { title: this.$t("employee.firstname"), property:"firstName", type: "string",  clickAction: "editEmployee" }, 
                    { title: this.$t("employee.lastname"), property: "lastName", type: "string", clickAction: "editEmployee" }, 
                    { title: this.$t("label.nfcChipId"), property: "nfcChipId", type: "string"}
                ],
                tbActions: [
                    { name: "editEmployee", icon: store.getters.svgIcons.pencil }
                ]
            },
            workstationTableData:{
                thFields: [
                    { title: this.$t("workstation.title"), property: "title", type: "string", clickAction: "setWorkstation" }, 
                    { title: this.$t("workstation.uuidWorkstation"), property:"uuidWorkstation", type: "string",  clickAction: "setWorkstation" }
                ],
                tbActions: [
                    { name: "setWorkstation", icon: store.getters.svgIcons.pencil }
                ]
            },
            adminEmployee: {} as Employee | null,
            employees: [] as Array<any>,
            employee: {} as Employee,
            workstations: [] as Array<any>,
            searchInput: "",
            form: {
                nfcChipId: '',
                adminChipId: ''
            },
            closeTimeout: 0,
            closeAfterSeconds: 5,
            closeAfterAdminSeconds: 10,
            timeoutLastSet: new Date(),
            setupWorkstation: false,
            setupChip: false,
            resetWorkstation: false,
            noAdminChipId: ''
        }
    },
    computed: {
        filteredEmployees() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.employees.forEach((employee : any) => {
                    (JSON.stringify(employee.firstName).toLowerCase().includes(this.searchInput.toLowerCase())  ||
                        JSON.stringify(employee.lastName).toLowerCase().includes(this.searchInput.toLowerCase())
                    ) && filtered.push(employee)
                })
            } else {
                filtered = this.employees;
            }
            return filtered;
        },
        filteredWorkstations() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.workstations.forEach((workstation : any) => {
                    (JSON.stringify(workstation.title).toLowerCase().includes(this.searchInput.toLowerCase()) ||
                    JSON.stringify(workstation.uuidWorkstation).toLowerCase().includes(this.searchInput.toLowerCase()) 
                    ) && filtered.push(workstation)
                })
            } else {
                filtered = this.workstations;
            }
            return filtered;
        },
        localStorageWorkstationUuid() {
            return WorkstationController.getLocalStorageUuidWorkstation()
        },
        currentHost() {
            return window.location.origin
        },
        workstation() {
            const workstations = this.workstations as Array<Workstation>
            const localUuid = this.localStorageWorkstationUuid as string
            return workstations.find((w: Workstation) => w.uuidWorkstation == localUuid)
        }
    },
    methods:{
        onNfcChipId(nfcChipId: string) {
            if (!this.adminEmployee && nfcChipId != 'ERROR' && nfcChipId != '') {
                this.getAdminEmployee(nfcChipId)
                //this.getEmployees
            } else if (this.employee.uid && nfcChipId != 'ERROR' && nfcChipId != '') {
                this.form.nfcChipId = nfcChipId
                this.refreshTimeout()
            }
        }, 
        onAdminChipIdChange() {
            if (this.form.adminChipId != '') this.onNfcChipId(this.form.adminChipId)
        },
        onBack() {
            this.resetData()
            this.$router.push('/workspace')
        },
        onCancel() {
            //this.$router.push('/workspace/home')
            this.resetData()
        },
        onSave() {
            this.saveChipId()
        },
        onSubmitPrevent() {
            this.saveChipId()
        },
        onResetWorkstation() {
            WorkstationController.setLocalStorageUuidWorkstation('')
            this.$router.push('/workspace')
        },
        onTableActionClicked(action : string, item:any) {
            switch(action) {
                case "editEmployee":
                    this.editEmployee(item);
                    break
                case "setWorkstation": 
                    WorkstationController.setLocalStorageUuidWorkstation(item.uuidWorkstation)
                    this.$notify(this.$t("success.workstationSet"), { position: "top", type: "success" });
                    break
            }
        },
        onBodyClicked() {
            this.refreshTimeout()
        },
        onTimeout() {
            this.removeBodyEventListeners()
            this.resetData()
            this.$router.push('/workspace')
        },
        resetData() {
            this.adminEmployee = null
            this.setupWorkstation = false
            this.setupChip = false
            this.resetWorkstation = false
            //this.noAdminChipId = ''
            this.form.nfcChipId = ''
            this.employee = {} as Employee
        },
            //functions
        editEmployee(employee: Employee) {
            this.employee = employee
            this.form.nfcChipId = employee.nfcChipId  
        },
        saveChipId() {
            this.saveEmployeeData()
        },
        refreshTimeout() {
            window.clearTimeout(this.closeTimeout)
                //call after 10 seconds
            this.closeTimeout = window.setTimeout(this.onTimeout, this.closeAfterAdminSeconds*1000)
            this.timeoutLastSet = new Date()
            //document.body.removeEventListener('click', this.onBodyClicked)
        },
        addBodyEventListeners() {
            document.body.addEventListener('click', this.onBodyClicked)
            document.body.addEventListener('touchstart', this.onBodyClicked)            
        },
        removeBodyEventListeners() {
            document.body.removeEventListener('click', this.onBodyClicked)
            document.body.removeEventListener('touchstart', this.onBodyClicked)
        },
        async getAdminEmployee(chipId: string) {
            const res = await EmployeeController.getEmployeeByChip(chipId);
            if (res.employee) {
                if (res.employee?.group?.isAdmin) {
                    this.noAdminChipId = ''
                    this.adminEmployee = res.employee
                    this.getEmployees()
                    this.getWorkstations()
                    this.addBodyEventListeners()
                    this.closeTimeout = window.setTimeout(this.onTimeout, this.closeAfterSeconds*1000)
                } else {
                    this.employees = []
                    this.workstations = []
                    this.$notify(this.$t(('error.employee.noAuthorization'), {model: this.$t('employee.title')}), { position: "right", type: "error", dismiss: false, dismissErrors: false });
                    this.noAdminChipId = chipId;
                    //this.$router.push('/workspace')
                }
            } else {
                this.$notify(this.$t(('error.status.' + res.status), {model: this.$t('employee.title')}), { position: "right", type: "error" });
                this.adminEmployee = null
            }
        },     
        async getEmployees() {
            const res = await EmployeeController.fetchEmployees();
            if(!res.error){
                this.employees = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getWorkstations() {
            const res = await WorkstationController.fetchWorkstations();
            if(!res.error){
                this.workstations = res.items
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },        
        async saveEmployeeData() {
            window.clearTimeout(this.closeTimeout)
            const res = await EmployeeController.updateChipId(this.employee.uid, this.form.nfcChipId)
            this.employee.nfcChipId = this.form.nfcChipId
            if (!res.error) {
                this.form.nfcChipId = ''
                this.$notify(this.$t("success.employeeNfcChipIdSet"), { position: "top", type: "success" });
                this.employee = {} as Employee
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }        
    }
})
