import axios from "axios";
import { Api } from "@/api/";
import { ObjectStorage, Employee } from "@/model/index";
import AxiosErrorService from "@/service/AxiosErrorService";

export default class EmployeeRepository  {
    static employees = new ObjectStorage([]); 

    static async fetchEmployees (){
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_EMPLOYEES_ACTION)
        .then((response:any) => {
            //console.log('fetchEmployees res', response)
            this.employees = new ObjectStorage(Employee.createEmployeesFromResponse(response));
            result = this.employees;
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async getEmployeeById(uid:any) {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_EMPLOYEE_BY_ID_ACTION + uid)
        .then((response:any) => {
            //console.log('getEmployeeById', response)
            if (response?.data?.employee) {
                result = {
                    employee: Employee.createEmployeeFromResponse(response.data.employee)
                }
            } else {
                result = null
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error;
            console.log('error catched', result)
        });
        
        return result;
    }
    
    static async getEmployeeByChip(chip: string) {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_EMPLOYEE_BY_CHIP_ACTION + chip)
        .then((response:any) => {
            //console.log('getEmployeeByChip', response)
            if (response?.data?.employee) {
                result = {
                    employee: Employee.createEmployeeFromResponse(response.data.employee)
                }
            } else {
                result = null
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error;
            console.log('error catched', result)
        });
        //console.log('return the error', result)
        return result
    }

    static async createEmployee(postData : FormData) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.CREATE_EMPLOYEE_ACTION, postData)
        .then((response:any) => {
            //console.log("createEmployee res", response)
            result = {
                employee: Employee.createEmployeeFromResponse(response.data.employee)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async updateEmployee(postData : FormData) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.UPDATE_EMPLOYEE_ACTION, postData)
        .then((response:any) => {
            //console.log("createEmployee res", response)
            result = {
                employee: Employee.createEmployeeFromResponse(response.data.employee)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async deleteEmployee(postData:any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.DELETE_EMPLOYEE_ACTION, postData)
        .then((response:any) => {
            //console.log("deleteEmployee res", response)
            result = {
                employee: Employee.createEmployeeFromResponse(response.data.employee)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;    
    }

    static async updateChipId(postData : string) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.EMPLOYEE_UPDATE_CHIPID_ACTION, postData)
        .then((response:any) => {
            //console.log("Employee addProject res", response.data.employee)
            result = {
                employee: Employee.createEmployeeFromResponse(response.data.employee)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async addProject(postData : string) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.EMPLOYEE_ADDPROJECT_ACTION, postData)
        .then((response:any) => {
            //console.log("Employee addProject res", response.data.employee)
            result = {
                employee: Employee.createEmployeeFromResponse(response.data.employee)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async removeProject(postData : string) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.EMPLOYEE_REMOVEPROJECT_ACTION, postData)
        .then((response:any) => {
            //console.log("Employee removeProject res", response)
            result = {
                employee: Employee.createEmployeeFromResponse(response.data.employee)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async fetchEmployeeGroups (){
        console.error('WARNING: static data from EmployeeRepository[fetchEmployeeGroups]')
        const employeeGroups = [
            { uid: 1, text: 'Montage 1'},
            { uid: 2, text: 'Softwareenwickler' },
            { uid: 3, text: 'Projektteam' }
        ]
        return employeeGroups;
    }
}

