
import { defineComponent } from 'vue';
import store from "@/store/index";
import { Button, IconButton, Card, DataList, Table, Switch } from '@/ui/index';
import { ClientController, ProjectController, TimeEntryController } from '@/controller/';
import { Client } from '@/model/index.ts';
import { Converter }from "@/utils";

export default defineComponent({
    name: "ClientDetail",
    components: { Button, IconButton, Card, DataList, Table, Switch },
    data() {
        return {
            store,
            client: {} as Client,
            timeEntries: [] as Array<any>,
            projects: [] as Array<any>,
            entriesTableData: {
                thFields: [{title: this.$t("label.date"), property: "startDay", type: "string", convert: "convertTimeStampToDate" }, { title:"Project", property: "project.title", type: "string"}, { title: this.$t("label.work"), property:"work.title", type: "string"}, { title: this.$t("label.hours"), property:"timeGone", type: "date", convert: "convertSecondsToTime"}],
            }
        }
    },
    created() {
        if (this.$route.params.id) {
            this.handleGetClient(this.$route.params.id);
            this.getProjectsByClient(this.$route.params.id);
            this.getTimeEntriesByClient(this.$route.params.id);
        }
    },
    computed: {
        timesByActivity() {
            if (this.timeEntries) {
                let sortedEntries = [] as Array<any>;
                this.timeEntries.forEach((entry : any) => {
                    if(!sortedEntries.some(item => item["uid"] === entry.work.uid)){
                        let newWork = {
                            uid: entry.work.uid,
                            title: entry.work.title,
                            timeGone: entry.timeGone
                        }
                        sortedEntries.push(newWork)
                    } else {
                        sortedEntries.forEach((workEntry : any, index: any) => {
                            if(workEntry.uid == entry.work.uid){
                                sortedEntries[index].timeGone += entry.timeGone; 
                            }
                        })
                    }
                });
                return sortedEntries;
            }
            return [];
        },
        timesByProject() {
            if (this.projects) {
                let sortedEntries = [] as Array<any>;
                this.timeEntries.forEach((entry : any) => {
                    if(!sortedEntries.some(item => item["uid"] === entry.project.uid)){
                        let newProject = {
                            uid: entry.project.uid,
                            title: entry.project.title,
                            timeGone: entry.timeGone
                        }
                        sortedEntries.push(newProject)
                    } else {
                        sortedEntries.forEach((projectEntry : any, index: any) => {
                            if(projectEntry.uid == entry.project.uid){
                                sortedEntries[index].timeGone += entry.timeGone; 
                            }
                        })
                    }
                });
                return sortedEntries;
            }
            return [];
        },
        timesByEmployee() {
            let employees = [] as Array<any>;

            this.timeEntries.forEach((entry : any)=> {
                if(!employees.find((employee : any) => entry.employee.uid == employee.uid)) {
                    employees.push({uid: entry.employee.uid, name: entry.employee.fullName, time: 0});
                }

                employees.forEach((employee : any, index: number) => {
                    if(entry.employee.uid == employee.uid && entry.getTimeGone()) {
                        employees[index].time += entry.getTimeGone();
                    }
                } )
            });
            //console.log('getTimeByEmployee ', employees)
            return employees;
        },        
        getTotalTime() {
            let time = 0;
            if (this.timeEntries) {
                this.timeEntries.forEach((entry : any) => {
                    time += parseInt(entry.timeGone);
                });
            }
            return Converter.convertSecondsToTime(time);
        },
        totalTimeByEmployee() {
            let total = 0;
            this.timesByEmployee.forEach((item:any) => {
                total += item.time;
            });
            return total;
        },            
    },
    methods:{
        handleGoBack() {
            this.$router.go(-1);
        },
        handleEdit(){
            this.$router.push("/administration/client/form/" + this.client.uid);
        },
        convertTime(time:any){
            return Converter.convertSecondsToTime(time);
        },
        convertSecondsToHours(seconds : number){
            return Converter.convertSecondsToTime(seconds);
        },
        convertTimeStampToDate(tstamp:any) {
            return Converter.convertTimeStampToDate(tstamp);
        },        
        async handleToggleActiveClient() {
            const postData = {
                'uid': this.client.uid,
                'status': !this.client.active
            }
            const res = await ClientController.toggleClient(postData);
            if (res.client) {
                this.client = res.client
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleConfirmDeleteClient() {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteClientText', { name: this.client.custName }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete() },
                //onCancel: () => { console.log("onCancel"); }
            });
        },
        async handleGetClient(uid:any) {
            const res = await ClientController.getClientById(uid);
            if (!res.error) {
                this.client = res.client;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleDelete() {
            const res = await ClientController.deleteClient(this.client.uid)
            if (!res.error) {
                this.$notify(this.$t("success.clientDeleted"), { position: "top" });
                this.$router.push("/administration/clients")
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getProjectsByClient(uid : any) {
            const res = await ProjectController.getProjectsByClient(uid);
            if (!res.error) {
                this.projects = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getTimeEntriesByClient(uid : any) {
            const res = await TimeEntryController.fetchClientEntries(uid);
            if (!res.error){
                this.timeEntries = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    } 
})
