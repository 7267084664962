import axios from "axios";
import { Api } from "../api";
import { ObjectStorage, TimeEntry, Workbreak } from "@/model";
import AxiosErrorService from "@/service/AxiosErrorService";

export default class TimeEntryRepository {
    static timeentries = new ObjectStorage([]); 

    static async fetchTimeEntries(postData:any) {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_TIMEENTRIES_ACTION + postData)
        .then((response:any) => {
            //console.log('fetchTimeEntries res', response)
            result = new ObjectStorage(TimeEntry.createTimeEntriesFromResponse(response));
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async fetchDayTimeEntries(postData:any) {
        let result = null;
        await axios.post(Api.BACKEND_URL + Api.GET_TIMEENTRIES_DAY_ACTION, postData)
        .then((response:any) => {
            //console.log('fetchDayTimeEntries res', response)
            result = new ObjectStorage(TimeEntry.createTimeEntriesFromResponse(response));
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async fetchProjectEntries(postData:any) {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_TIMEENTRIES_PROJECT_ACTION + postData)
        .then((response:any) => {
            //console.log('fetchProjectEntries res', response)
            result = new ObjectStorage(TimeEntry.createTimeEntriesFromResponse(response));
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async fetchEmployeeEntries(postData:any) {
        let result = null;
        await axios.post(Api.BACKEND_URL + Api.GET_TIMEENTRIES_EMPLOYEE_ACTION, postData)
        .then((response:any) => {
            //console.log('fetchEmployeeEntries res', response)
            result = new ObjectStorage(TimeEntry.createTimeEntriesFromResponse(response));
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }
    
    static async fetchClientEntries(postData:any) {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_TIMEENTRIES_CLIENT_ACTION + postData)
        .then((response:any) => {
            //console.log('fetchClientEntries res', response)
            result = new ObjectStorage(TimeEntry.createTimeEntriesFromResponse(response));
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async fetchRunningTimeEntries(postData:any) {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_TIMEENTRIES_RUNNING_ACTION + postData)
        .then((response:any) => {
            //console.log('fetchRunningTimeEntries res', response)
            result = new ObjectStorage(TimeEntry.createTimeEntriesFromResponse(response))
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async getTimeEntry(postData:any) {        
        const response: any  = await axios.get(Api.BACKEND_URL + Api.GET_TIMEENTRY_ACTION + postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        
        //console.log('getTimeEntry response', response)
        const entry = TimeEntry.createTimeEntryFromResponse(response?.data?.timeentry)
        //console.log('getTimeEntry response entry', entry)
        return entry;
    }

    static async createTimeEntry(postData:any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.CREATE_TIMEENTRY_ACTION, postData)
        .then((response:any) => {
            //console.log("createTimeEntry res", response)
            result = new ObjectStorage(TimeEntry.createTimeEntriesFromResponse(response))
            /* result = {
                timeentry: TimeEntry.createTimeEntryFromResponse(response.data.timeentry)
            } */
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;

    }

    static async toggleTimeEntry(postData:any) {        
        const response = await axios.post(Api.BACKEND_URL + Api.TOGGLE_TIMEENTRY_ACTION, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        if ((response as any).error) {
            return response
        } else {
            //console.log('toggleTimeEntry response', response)
            const entries = new ObjectStorage(TimeEntry.createTimeEntriesFromResponse(response))
            const workbreak = (response as any)?.data?.workbreak ? Workbreak.createBreakFromResponse((response as any).data.workbreak.workbreak) : {}
            //console.log('toggleTimeEntry response ', response)
            return { entries: entries, workbreak: workbreak }
        }
    }

    static async deleteTimeEntry(postData:any) {

        const response = await axios.create().post(Api.BACKEND_URL + Api.DELETE_TIMEENTRY_ACTION, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        const entries = new ObjectStorage(TimeEntry.createTimeEntriesFromResponse(response))
        return entries
    }

    static async updateTimeEntry(postData:any) {
        let result = null;
        const response:any = await axios.create().post(Api.BACKEND_URL + Api.UPDATE_TIMEENTRY_ACTION, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        //console.log("updateTimeEntry res", response)
        result = TimeEntry.createTimeEntryFromResponse(response?.data?.timeentry)
            /* result = {
                timeentry: TimeEntry.createTimeEntryFromResponse(response.data.timeentry)
            } */

        return result;
    }    
}
