import { WorkstationRepository } from "../repository";
import { Workstation } from "@/model";
import { useEventBus } from '@vueuse/core'
import { appEventKey } from '@/bus/eventkey'

const WorkstationController = {
    localWorkstation: {} as Workstation,
    eventBus: useEventBus(appEventKey),

    setLocalStorageUuidWorkstation(uuid: string) {
        return localStorage.setItem('TimiTools.UuidWorkstation', uuid)
    },

    getLocalStorageUuidWorkstation() {
        return localStorage.getItem('TimiTools.UuidWorkstation')
    },

    async fetchWorkstations(){
        const res : any = await WorkstationRepository.fetchWorkstations();
        return res;
    },
    async getWorkstationById(uid:any){
        const res : any = await WorkstationRepository.getWorkstationById(uid)
        return res;
    },
    async getLocalWorkstation(){
        const localUuid = this.getLocalStorageUuidWorkstation()
        if (localUuid) {
            const postData = `uuid=${localUuid}`;
            const res : any = await WorkstationRepository.getWorkstationByUuid(postData)
            this.localWorkstation = res
            this.eventBus.emit('onWorkstation')
            return res
        } else {
            return null
        }
    },
    async createWorkstation(formData : any) {
        const works = JSON.stringify(formData.works)
        const postData = `title=${formData.title}&department=${formData.department}&workarea=${formData.workarea}
            &unit=${formData.machine}&nfcchipReader=${formData.nfcChipReader}&presenceAllowed=${formData.presenceAllowed}
            &workbreakAllowed=${formData.workbreakAllowed}&timeentryAllowed=${formData.timeentryAllowed}&works=${works}`;
        const res : any = await WorkstationRepository.createWorkstation(postData)
        return res;
    },
    async updateWorkstation(formData : any) {
        const works = JSON.stringify(formData.works)
        const postData = `uid=${formData.uid}&title=${formData.title}&department=${formData.department}&workarea=${formData.workarea}
        &unit=${formData.machine}&nfcChipReader=${formData.nfcChipReader}&presenceAllowed=${formData.presenceAllowed}
        &workbreakAllowed=${formData.workbreakAllowed}&timeentryAllowed=${formData.timeentryAllowed}&works=${works}`;
        const res : any = await WorkstationRepository.updateWorkstation(postData);
        return res;
    },
    async deleteWorkstation(workstationId : number) {
        const postData = `uid=${workstationId}`;
        const res : any = await WorkstationRepository.deleteWorkstation(postData);
        return res;
    }
}
export default WorkstationController;