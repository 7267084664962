
import { defineComponent } from 'vue';
import store from '@/store/index';
import { IconButton, List, ListItem } from '@/ui/index';
import { TimeEntry } from '@/model';
import { DateTimeUtils } from "@/utils/";

export default defineComponent({
    name: "Overview",
    components: { IconButton, List, ListItem },
    data() {
        return {
            store
        }
    },
    props: {
        dataLoaded: {
            type: Boolean,
            default: false
        },
        timeEntries: {
            type: Array,
            default: [] as Array<TimeEntry>
        },
        resetSwipe: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        allowSwipe: {
            type: Boolean,
            default: false
        },
        activeTimeEntry:  {
            type: Object,
            default: {} as TimeEntry
        },
        timeTracked: {
            type: String,
            default: '00:00 h'
        }
    },
    created() {
        //
        //console.log('allowSwipe: ', this.allowSwipe);
    },
    methods: {
            //handler
        onDeleteTimeEntry(timeEntry: TimeEntry) {
            this.$emit('onDelete', timeEntry)
        },
        onEditTimeEntry(timeEntry: TimeEntry) {
            this.$emit('onEdit', timeEntry)
        },
        onTimeEntrySwipeLeft(timeEntry: TimeEntry) {
            this.$emit('onSwipeLeft', timeEntry)
            /* this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteTimeEntryText' ), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.deleteTimeEntry(timeEntry) },
                onCancel: () => { 
                    this.resetSwipe = true
                    this.$nextTick(() => {
                        this.resetSwipe = false
                    })
                }
            }); */            
        },
        onTimeEntrySwipeRight(timeEntry: TimeEntry) {
            //this.editTimeEntry(timeEntry)
            this.$emit('onSwipeRight', timeEntry)
        },
        onToggleTimeEntry(timeEntry: TimeEntry, startstop: string) {
            this.$emit('onToggle', timeEntry, startstop)
        },
            //functions
        getProjectTitle(timeEntry: TimeEntry) {
            const p = timeEntry.project
            let title = p.numberExternal ? p.numberExternal : ''
            title += p.numberInternal ? (title ? ' / ' : '') + p.numberInternal : ''
            title += p.numberSerial ? (title ? ' / ' : '') + p.numberSerial : ''
            title += p.title ? (title ? ' ' : '') + p.title : ''
            return title
        },     
        convertSeconds(ts: any) {
            return DateTimeUtils.convertSecondsToTime(ts)
        }        
    }
})

