
import store from '@/store/index';
import { defineComponent } from "vue";
import { PresenceController, EmployeeController, TimeEntryController, WorkbreakController } from "@/controller/";
import { Employee, Presence, TimeEntry, Workbreak } from "@/model";
import { Card, IconButton, DateInput } from "@/ui";
import { TimeEntryCard, TimeEntryForm, /* PresenceForm, WorkbreakForm, */ MultipleWorkbreakForm, MultiplePresenceForm }  from "@/components";
import { /* Converter, */ DateTimeUtils } from "@/utils";

export default defineComponent({
    name: "DashboardEmployee",
    components: { Card, TimeEntryCard, TimeEntryForm, /* PresenceForm, WorkbreakForm, */MultipleWorkbreakForm, MultiplePresenceForm, IconButton, DateInput },
    props: {
        timeentries: {
            type: Array,
            default: null
        }
    },
    data(){
        return {
            store,
            employeeId: 0,
            employee: {} as Employee,
            timeEntries: [] as Array<TimeEntry>,
            presences: [] as Array<Presence>,
            workbreaks: [] as Array<Workbreak>,
            activeDay: Math.floor(Date.now() / 1000),
            userMode: 'admin',
            editEntry: {} as TimeEntry | null,
            editPresence: {} as Presence | null,
            editPresences: [] as Presence[],
            editWorkbreak: {} as Workbreak | null,
            editWorkbreaks: [] as Workbreak[],
            timeEntryFormVisible: false,
            presenceFormVisible: false,
            workbreakFormVisible: false,
            dataLoaded: {
                presence: false,
                timeentries: false,
                workbreaks: false
            },
            dateInputEnabled: false
        }
    },
    created(){
        if (this.$route.params.date) this.activeDay = parseInt(this.$route.params.date as string)
        this.employeeId = parseInt(this.$route.params.id as string)
        this.getEmployee();
    },
    computed: {
        displayActiveDay() : string {
            return DateTimeUtils.convertTimeStampToDate(this.activeDay, true)
        },        
        timeSum() {
            let sum = 0;
            this.timeEntries.forEach((entry : any) => {
                sum += entry.countingTime//entry.getTimeGone()
            })
            return sum;
        },
        latestInteraction() {
            let last = 0;
            this.timeEntries.forEach((entry : any) => {
                if (entry.tstamp > last) {
                    last = entry.tstamp;
                }
            })
            return this.convertToTime(last);
        },
        activePresence() {
            if (this.presences.length) {
                return this.presences[this.presences.length-1]
            } else {
                return {
                    come: 0,
                    go: 0
                }
            }
        },
        presenceTime() {
                //take the last one cause the first is always the running, if there are more
            const pr = this.presences.length ? this.presences[this.presences.length-1] : null
            if (pr) {
                if (pr.go) {
                    return pr.go - pr.come
                } else {
                    return Math.floor(new Date().valueOf() / 1000) - pr.come
                }
            } else {
                return '-'
            }
        }
    },
    methods: {
        convertToTime(ts:number) {
            return DateTimeUtils.convertTimeStampToTime(ts)
        },
        convertTimeStampToTime(ts: number) {
            return DateTimeUtils.convertTimeStampToTime(ts)
        },
        convertTimeStampToDate(ts: number) {
            return DateTimeUtils.convertTimeStampToDate(ts, false)
        },
        convertSecondsToTime(ts:number) {
            return DateTimeUtils.convertSecondsToTime(ts)
        },
        convertSeconds(ts: any) {
            return DateTimeUtils.convertSecondsToTime(ts)
        },
        getEmployeeData() {
            if (this.employee) {
                this.getPresences();
                this.getTimeEntries();
                this.getWorkbreaks();
            }
        },

            //handler
        onSwitchCalendarButton() {
            this.dateInputEnabled = true
        },
        onDateInputDateChange(value: string) {
            if (value) {
                const d = (DateTimeUtils.getDayNoonTimestamp(new Date(value))*1000) / 1000
                this.activeDay = d
                this.getEmployeeData()
                this.dateInputEnabled = false
            }            
        },            
        handleSwitchDay(direction: any) {
            this.activeDay += 86400*direction
            const midnights = DateTimeUtils.getMidnightToMidnight(new Date())
            if (this.activeDay > midnights.end) {
                this.activeDay -= 86400;
            } else {
                this.getEmployeeData()
            }
        },   
        handleGoBack() {
            this.$router.push('/administration/dashboard')
        },
        onDeleteTimeEntry(entry: TimeEntry) {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteTimeEntryText'), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.deleteTimeEntry(entry) },
                //onCancel: () => { console.log("onCancel"); }
            });               
        },
        onEditTimeEntry(entry: TimeEntry) {
            this.editEntry = entry
            this.timeEntryFormVisible = true
        },
        onTimeentryEditFormCancel() {
            this.timeEntryFormVisible = false
            this.editEntry = null
        },
        onTimeentryEditFormUpdate(entry: TimeEntry) {
            const replaceIdx = this.timeEntries.findIndex((e: any) => e.uid == entry.uid)
            if (replaceIdx != -1) {
                this.timeEntries.splice(replaceIdx, 1, entry)
            }
            this.timeEntryFormVisible = false
            this.editEntry = null
        },
        onPresenceForm(presence: Presence) {
            //this.editPresence = presence
            this.editPresences = this.presences
            this.presenceFormVisible = !this.presenceFormVisible
        },
        onPresenceEditFormCancel() {
            this.presenceFormVisible = false
        },        
        /* onPresenceEditFormUpdate(presence: Presence) {
            const replaceIdx = this.presences.findIndex((p: any) => p.uid == presence.uid)
            if (replaceIdx != -1) {
                this.presences.splice(replaceIdx, 1, presence)
            }
            this.presenceFormVisible = false
            this.editPresence = null
        },
        onRemovePresence(presence: Presence, comeGo: string) {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.removePresenceText'), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { 
                    this.removePresenceTime(presence, comeGo) 
                },
            });                 
        }, */

        onPresenceEditFormUpdate(presence: Presence) {
            const idx = this.presences.findIndex((p: Presence) => p.uid == presence.uid)
            if (idx != -1) {
                this.presences.splice(idx, 1, presence)
            }
            this.editPresence = null
        },
        onPresenceEditFormDelete(presence: Presence) {
            const idx = this.presences.findIndex((p: Presence) => p.uid == presence.uid)
            if (idx != -1) {
                this.presences.splice(idx, 1)
            }
            //this.editPresence = null
        },        
        /* onPresenceEditFormCancel() {
            //this.presenceFormVisible = false
            this.editPresence = null
        }, */
        onPresenceEditFormError(error: any) {
            //console.log('onPresenceEditFormError: ', error)
        },
        onPresenceEditFormClose(changed: boolean) {
            this.editPresences = []
            this.presenceFormVisible = false
        }, 

        onWorkbreakForm(workbreak: Workbreak) {
            //console.log('this.workbreaks', this.workbreaks)
            //this.editWorkbreaks = this.workbreaks.filter((wb: Workbreak) => item.workbreaks.includes(wb.uid))            
            this.editWorkbreaks = this.workbreaks
            this.workbreakFormVisible = !this.workbreakFormVisible
        },
        /* onWorkbreakEditFormCancel() {
            this.workbreakFormVisible = false
        }, */       
        /* onWorkbreakEditFormUpdate(workbreak: Workbreak) {
            const replaceIdx = this.workbreaks.findIndex((w: any) => w.uid == workbreak.uid)
            if (replaceIdx != -1) {
                this.workbreaks.splice(replaceIdx, 1, workbreak)
            }
            this.workbreakFormVisible = false
            this.editWorkbreak = null
        }, */
        /* onRemoveWorkbreak(workbreak: Workbreak) {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteWorkbreakText'), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { 
                    this.removeWorkbreak(workbreak) 
                },
            });                 
        }, */
        
        onWorkbreakEditFormClose(changed: boolean) {
            //if (changed) this.setEmployeeStatistics()
            this.editWorkbreaks = []
            this.workbreakFormVisible = false
        },
        onWorkbreakEditFormUpdate(workbreak: Workbreak) {
            const idx = this.workbreaks.findIndex((wb: Workbreak) => wb.uid == workbreak.uid)
            if (idx != -1) {
                this.workbreaks.splice(idx, 1, workbreak)
            }            
        },
        onWorkbreakEditFormDelete(workbreak: Workbreak) {
            const idx = this.workbreaks.findIndex((wb: Workbreak) => wb.uid == workbreak.uid)
            if (idx != -1) {
                this.workbreaks.splice(idx, 1)
            }
            //this.editPresence = null
        },          
            //
        removeTimeEntries(entries: any) {
                //TODO put this into controller and get the entries again from controller
            entries.forEach((entry: any) => {
                const replaceIdx = this.timeEntries.findIndex((e: any) => e.uid == entry.uid)
                if (replaceIdx != -1) {
                    this.timeEntries.splice(replaceIdx, 1)
                }
            })
            //this.lastTimeEntries = this.getLastTimeEntries()
        },            
        formatDateInputValue(d: number) {
            return DateTimeUtils.formatDateForInput(new Date(d*1000))
        },             
            //data post

        async deleteTimeEntry(entry: TimeEntry) {
            const deleted = await TimeEntryController.deleteTimeEntry(entry.uid, entry.tstamp)
            //console.log('deleteTimeEntry deleted', deleted)
            if (deleted?.items) this.removeTimeEntries(deleted.items)
        },        
        async removePresenceTime(presence: Presence, comeGo: string) {
            if (comeGo == 'come') presence.come = 0
            else if (comeGo == 'go') presence.go = 0
            const result = await PresenceController.updatePresence(
                presence.uid, 
                presence.employee, 
                presence.come,
                presence.go, 
                presence.comeWorkstation, 
                presence.goWorkstation
            )
            if (result?.uid) {
                const replaceIdx = this.presences.findIndex((p: any) => p.uid == result.uid)
                if (replaceIdx != -1) {
                    this.presences.splice(replaceIdx, 1, result)
                }                
            }
        },  
        async removeWorkbreak(workbreak: Workbreak) {
            const result = await WorkbreakController.deleteWorkbreak(workbreak.uid)
            //console.log('removeWorkbreak', result, this.workbreaks)
            if (result?.uid) {
                const replaceIdx = this.workbreaks.findIndex((w: any) => w.uid == result.uid)
                //console.log('removeWorkbreak replaceIdx', replaceIdx)
                if (replaceIdx != -1) {
                    this.workbreaks.splice(replaceIdx, 1)
                }                
            }
        },  
            //data get
        async getEmployee() {
            if (this.employeeId) {
                const res = await EmployeeController.getEmployeeById(this.employeeId)
                if (res.employee) this.employee = res.employee
                this.getEmployeeData()
            }
        },
        async getTimeEntries() {
            this.dataLoaded.timeentries = false
            const day = DateTimeUtils.getDayNoonTimestamp(new Date(this.activeDay*1000));
            const res = await TimeEntryController.fetchDayTimeEntries([this.employeeId], day)
            if(!res.error){
                this.timeEntries = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
            this.dataLoaded.timeentries = true
        },
        async getPresences() {
            this.dataLoaded.presence = false
            const date = new Date()
            date.setTime(this.activeDay*1000)
            //const day = DateTimeUtils.getMidnightToMidnight(date)^
            const day = DateTimeUtils.getDayNoonTimestamp(date);
            const res = await PresenceController.getPresences(day, day, this.employeeId);
            if(!res.error){
                this.presences = res.items;
            } else {
                this.presences = []
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }       
            this.dataLoaded.presence = true
        },
        async getWorkbreaks() {
            this.dataLoaded.workbreaks = false
            const date = new Date()
            date.setTime(this.activeDay*1000)
            //const day = DateTimeUtils.getMidnightToMidnight(date)            
            const day = DateTimeUtils.getDayNoonTimestamp(date);
            const response = await WorkbreakController.getWorkbreaks(day, day, this.employeeId)
            if (response.items) {
                this.workbreaks = response.items
            } else {
                this.workbreaks = []
            }
            this.dataLoaded.workbreaks = true
        }
        

    },
})
