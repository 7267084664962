
import { defineComponent } from 'vue'
import { PresenceController } from '@/controller/'
import { UiTimeInput, DateInput, Button } from '@/ui/index'
import { Presence } from '@/model'
import { DateTimeUtils } from '@/utils'
import store from '@/store/index'

export default defineComponent({
    name: "PresenceForm",
    components: { UiTimeInput, DateInput, Button },
    props: {
        presence: {
            type: Presence, 
            default: null
        }
    },    
    data(){
        return{
            store,
            form:{
                employeeId: 0,
                come: 0,
                go: 0,
                comeWorkstation: 0,
                goWorkstation: 0
            },
            timeChanged: false,
            formInvalid: false,
            icons: {
                attention: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" version="1.1" viewBox="0 0 16 18">  <g>        <circle cx="8" cy="9" r="7.2" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".9"/>        <line x1="8" y1="10.6" x2="8" y2="4.8" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".9"/>        <line x1="8" y1="12.6" x2="8" y2="12.6" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".9"/>  </g></svg>'
            }
        }
    },
    watch: {
        presence() {
            //console.log('watch presence', this.presence)
        }
    },
    mounted() {
        this.setFormValues()
    },
    methods: {
        formatDateForInput(ts: number) {
            return ts ? DateTimeUtils.formatDateForInput(new Date(ts*1000)) : ''
        },
        getTimeFromTS(ts: number) {
            return ts ? DateTimeUtils.getTimeOnly(new Date(ts*1000)) : 0
        },
        onCancel() {
            this.$emit('cancel')
        },
        onSubmitPrevent() {
            this.updatePresence()
        },
        onTimeChanged(property: string, value: any) {
            const d = DateTimeUtils.getDateOnly(new Date((property == 'come' ? this.form.come : this.form.go)*1000)) + value.sec
            if (property == 'come') {
                this.form.come = d
            } else {
                this.form.go = d
            }
            this.timeChanged = true
            this.formInvalid = this.form.come > this.form.go
        },
        onDateChanged(property: string, value: any) {
                //string Datum
            const d = DateTimeUtils.getDateOnly(new Date(value)) + DateTimeUtils.getTimeOnly(new Date((property == 'come' ? this.form.come : this.form.go)*1000));
            if (property == 'come') {
                this.form.come = d
            } else {
                this.form.go = d
            }
            this.timeChanged = true
            this.formInvalid = this.form.come > this.form.go
        },
        onSave() {
            this.updatePresence()
        },
        setFormValues() {
            this.form.employeeId = this.presence?.employee
            this.form.come = this.presence?.come
            this.form.go = this.presence?.go
            this.form.comeWorkstation = this.presence?.comeWorkstation
            this.form.goWorkstation = this.presence?.goWorkstation
        },
        async updatePresence() {

                //send ms to server
            const result = await PresenceController.updatePresence(
                this.presence.uid, 
                this.presence.employee, 
                this.form.come, 
                this.form.go, 
                this.form.comeWorkstation, 
                this.form.goWorkstation
            )
            if (result?.uid) {
                //console.log('handleUpdateTimeEntry', result)
                //this.$router.go(-1) 
                this.$emit('update', result)
            } else {
                //show erro message
                this.$emit('error')
            }
        },
    },
})
