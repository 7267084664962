
import store from "@/store";
import { Button, Searchbar, Table } from "@/ui";
import { defineComponent } from "vue";
import { EmployeegroupController } from "@/controller/";

export default defineComponent({
    name: "EmployeeList",
    components: { Button, Searchbar, Table },
    created(){
        this.getEmployeegroups()
    },
    data(){
        return{
            store,
            employeegroupTableData:{
                thFields: [
                    { title: this.$t("employeegroup.title"), property: "title", type: "string", clickAction: "openDetail" }, 
                    { title: this.$t("employeegroup.systemGroup"), property: "systemGroup", type: "boolean", disabled: true, action: "toggleSystemGroup" }, 
                    { title: this.$t("employeegroup.isAdmin"), property:"isAdmin", type: "boolean", disabled: true, action: "toggleIsAdmin" }, 
                    { title: this.$t("employeegroup.isDefault"), property: "isDefault", type: "boolean", disabled: true, action: "toggleIsDefault" }
                ],
                tbActions: [
                    { name: "openDetail", icon: store.getters.svgIcons.watch }, 
                    { name: "editEmployeegroup", icon: store.getters.svgIcons.pencil }, 
                    { name: "confirmDelete", icon: store.getters.svgIcons.remove }]
            },
            employeegroups: [] as Array<any>,
            searchInput: ""
        }
    },
    computed: {
        filteredEmployeegroups() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.employeegroups.forEach((employeegroup : any) => {
                    JSON.stringify(employeegroup.title).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(employeegroup);
                    !filtered.includes(employeegroup) && JSON.stringify(employeegroup.title).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(employeegroup);
                })
            } else {
                filtered = this.employeegroups;
            }
            return filtered;
        }
    },
    methods:{
        async getEmployeegroups() {
            const res = await EmployeegroupController.fetchEmployeegroups();
            if(!res.error){
                this.employeegroups = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleTableActionClicked(action : string, item:any) {
            //console.log("handleTable", action, item)
            switch(action) {
                case "openDetail":
                    this.openDetailView(item.uid);
                    break
                case "confirmDelete":
                    this.handleConfirmDeleteProject(item);
                    break
                case "editEmployeegroup":
                    this.editEmployeegroup(item.uid);
                    break
            }
        },
        handleShowCreateForm(){
            this.$router.push("/administration/employeegroup/form/0");
        },
        openDetailView(uid : number) {
            this.$router.push("/administration/employeegroup/detail/" + uid);
        },
        editEmployeegroup(uid:number) {
            this.$router.push("/administration/employeegroup/form/" + uid);
        },
        handleConfirmDeleteProject(item : any) {
            this.$confirm(this.$t("confirm.delete"), this.$t("confirm.deleteEmployeeText", { name: item.firstName + item.lastName }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete(item.uid) },
                //onCancel: () => { console.log("onCancel"); }
           });
        },
        async handleDelete(uid : number) {

            /* const res = await EmployeegroupController.deleteEmployeegroup(uid);
            if (!res.error) {
                this.employees = this.employees.filter(function(item : any) {
                   return item.uid != res.employee.uid;
                });
                this.$notify(this.$t("success.employeeDeleted"), { position: "top" });
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            } */
        }
    }
})
