
    import { defineComponent } from 'vue';
    import { Project, TimeEntry } from '@/model';
    import { ProjectController } from '@/controller/';
    import { Button, List, ListItem } from '@/ui/';

    export default defineComponent({
        name: "ProjectDetail",
        components: { Button, List, ListItem },
        data(){
            return{
                project: {} as Project,
                timeEntries: [] as Array<TimeEntry>,
            }
        },
        created(){
            if (this.$route.params.id) {
                this.handleGetProjectDetail(this.$route.params.id)
            }
        },
        methods:{
            handleGoBack(){
                this.$router.go(-1)
            },  
            async handleGetProjectDetail(uid:any){
                this.project = await ProjectController.getProjectById(uid);
            },
            async handleGetProjectTiemEntries() {
                //console.log("TODO: handleGetProjectTiemEntries");
            }
        }
    })
    