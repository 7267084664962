
import { defineComponent } from 'vue'
import { WorkbreakController } from '@/controller/'
import { UiTimeInput, Button } from '@/ui/index'
import { Workbreak } from '@/model'
import { DateTimeUtils } from '@/utils'
import store from '@/store/index'

export default defineComponent({
    name: "WorkbreakForm",
    components: { UiTimeInput, Button },
    props: {
        workbreak: {
            type: Workbreak, 
            default: null
        }
    },    
    data(){
        return{
            store,
            form:{
                employeeId: 0,
                start: 0,
                stop: 0,
                startWorkstation: 0,
                stopWorkstation: 0
            },
            timeChanged: false,
            startDay: 0,
            stopDay: 0
        }
    },
    watch: {
        workbreak() {
            //console.log('watch workbreak', this.workbreak)
        }
    },
    mounted() {
        this.setFormValues()
    },
    methods: {
        formatDateForInput(ts: number) {
            return ts ? DateTimeUtils.formatDateForInput(new Date(ts*1000)) : ''
        },
        getTimeFromTS(ts: number) {
            return ts ? DateTimeUtils.getTimeOnly(new Date(ts*1000)) : 0
        },
        onCancel() {
            this.$emit('cancel')
        },
        onSubmitPrevent() {
            this.updateWorkbreak()
        },
        onTimeChanged(property: string, value: any) {
            //const d = DateTimeUtils.getDateOnly(new Date((property == 'come' ? this.form.come : this.form.go)*1000)) + value.sec
            if (property == 'start') {
                this.form.start = this.startDay + value.sec
            } else {
                this.form.stop = this.stopDay +  value.sec
            }
            this.timeChanged = true
        },
        onSave() {
            this.updateWorkbreak()
        },
        setFormValues() {
            this.form.employeeId = this.workbreak?.employee
            this.form.start = this.workbreak?.start
            this.form.stop = this.workbreak?.stop
            this.form.startWorkstation = this.workbreak?.startWorkstation
            this.form.stopWorkstation = this.workbreak?.stopWorkstation
            this.startDay = DateTimeUtils.getDateOnly(new Date((this.form.start)*1000))
            this.stopDay = DateTimeUtils.getDateOnly(new Date((this.form.stop)*1000))
        },
        async updateWorkbreak() {

                //send ms to server
            const result = await WorkbreakController.updateWorkbreak(
                this.workbreak.uid, 
                this.workbreak.employee, 
                this.form.start,
                this.form.stop,
                this.form.startWorkstation, 
                this.form.stopWorkstation
            )
            if (result?.uid) {
                //console.log('handleUpdateTimeEntry', result)
                //this.$router.go(-1) 
                this.$emit('update', result)
            } else {
                //show erro message
            }
        },
    },
})
