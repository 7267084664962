
import store from '@/store/index';
import { IconButton } from '@/ui/index';
import { defineComponent, onMounted, ref, computed, watch} from 'vue';

export default defineComponent({
  name: "SearchList",
  components: { IconButton },
  props: {
      item: {
        type: Object,
        default: null
      },
      options: {
        type: Array,
        default: null
      },
      secondOptionGroup: {
        type: Array,
        default: null
      },
      storedValue: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      primary: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ""
      },
      allowCreateNew: {
        type: Boolean,
        default: false
      },
      subheader: {
        type: Array,
        default: null
      },
      labelProperty: {
        type: String,
        default: ""
      },
  },
  data() {
    return {
      store
    }
  },
  setup(props, { emit }) {
    const domSelected = ref<HTMLParagraphElement|null>(null);
    const value = ref(0);
    const inputValue = ref("");
    const listActive = ref(false);
    const selectedValue = ref({
      text: "",
      value: 0
    });

    const selectListStyle = computed(() => {
      let style = "";
      if (domSelected.value) {
        const br = domSelected?.value?.getBoundingClientRect();
        style = listActive.value ? "top: " + Number(br.height) +  "px; width: " + (br.width - 2) + "px;" : "";
      }
      return style;
    })

    const filteredOptions = computed(() => {
        let filteredList : any = props.options;       
        filteredList = filteredList.filter((item:any)=>{
          if(inputValue.value && item.text.toLowerCase().includes(inputValue.value.toLowerCase())) {
            return item
          } else {
            return item
          }
        })
        if(inputValue.value != "" && listActive.value == false) {
          handleToggleList();
          if (filteredList.length == 1 && inputValue.value == filteredList[0].text) {
            handleHideList();
          }
        }
        return filteredList
    })

    const filteredSecondOptionGroup = computed(() => {
      if(props.secondOptionGroup != null) {
        let filteredList : any = props.secondOptionGroup;
          filteredList = filteredList.filter((item:any) => {
            if (inputValue.value && item.text.toLowerCase().includes(inputValue.value.toLowerCase())) {
              return item;
            }  else {
              return item
            }
          })
          if (inputValue.value != "" && listActive.value == false) {
            handleToggleList();
            if(filteredList.length == 1 && inputValue.value == filteredList[0].text) {
              handleHideList();
            }
          }
          return filteredList;
        }
      return [];
    })
    
    onMounted(() => {
      //value.value = props.storedValue.value;
      //selectedValue.value.text = props.storedValue.text;
      //console.log("onmounted", props, value, selectedValue);
    })

    const handleHideList = () => {
      listActive.value = false;
    }

    const isSelectedItem = (uid:any) => {
      if(uid == selectedValue.value.value) {
        return true;
      } 
      return false;
    }

    const handleToggleList = () => {
      listActive.value = !listActive.value;
    }

    const handleSelectListClick = (ev : Event) => {
      const target = ev?.target as HTMLElement;

      if (target.nodeName == "LI") {
        //console.log('handleSelectListClick', target.dataset['value'], target.innerHTML.trim());
        selectedValue.value.value = Number(target.dataset['value'])
        value.value = Number(target.dataset['value']);
        inputValue.value = target.innerHTML.trim();
        selectedValue.value.text = target.innerHTML.trim();
        listActive.value = false;
        //emit("onChange", props.item, value.value, selectedValue.value.text);
        emit("onChange", value.value, selectedValue.value.text);
      }
      handleHideList()
    }

    const handleAddItemClicked = (ev : Event) => {
      const target = ev?.target as HTMLElement;

      let formData = {
        name: inputValue.value
      }
      emit("handleAddItemClicked", inputValue.value);
    }

    watch(() => props.storedValue, (oldValue, newValue) => {
      //console.log('watch props.storedValue, oldValue, newValue', props.storedValue)
      value.value = props.storedValue.value;
      selectedValue.value.text = props.storedValue.text;
      inputValue.value = props.storedValue.text;
      listActive.value = false;

    })

    return {
      domSelected,
      value,
      inputValue,
      listActive,
      selectedValue,
      selectListStyle,
      filteredOptions,
      filteredSecondOptionGroup,
      isSelectedItem,
      handleToggleList,
      handleHideList,
      handleSelectListClick,
      handleAddItemClicked
    }
  },
})
