import axios from "axios";
import { Api } from "@/api/";
import { ObjectStorage, Employeegroup } from "@/model/index";
import AxiosErrorService from "@/service/AxiosErrorService";

export default class EmployeegroupRepository  {
    static employeegroups = new ObjectStorage([]); 

    static async fetchEmployeegroups (){
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_EMPLOYEEGROUPS_ACTION)
        .then((response:any) => {
         //console.log('fetchEmployeegroups res', response)
            this.employeegroups = new ObjectStorage(Employeegroup.createEmployeegroupsFromResponse(response));
            result = this.employeegroups;
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async getEmployeegroupById(uid:any) {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_EMPLOYEEGROUP_BY_ID_ACTION + uid)
        .then((response:any) => {
            //console.log('getEmployeegroupById', response)
            result = {
                employeegroup: Employeegroup.createEmployeegroupFromResponse(response.data.employeegroup)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }
    
    static async createEmployeegroup(postData : any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.CREATE_EMPLOYEEGROUP_ACTION, postData)
        .then((response:any) => {
            //console.log("createEmployeegroup res", response)
            result = {
                employeegroup: Employeegroup.createEmployeegroupFromResponse(response.data.employeegroup)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async updateEmployeegroup(postData : any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.UPDATE_EMPLOYEEGROUP_ACTION, postData)
        .then((response:any) => {
            //console.log("createEmployeegroup res", response)
            result = {
                employeegroup: Employeegroup.createEmployeegroupFromResponse(response.data.employeegroup)
                }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    /* static async deleteEmployee(postData:any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.DELETE_EMPLOYEE_ACTION, postData)
        .then((response:any) => {
            console.log("deleteEmployee res", response)
            result = {
                employee: Employee.createEmployeeFromResponse(response.data.employee)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;    
    }
    static async fetchEmployeeGroups (){
        console.error('WARNING: static data from EmployeeRepository[fetchEmployeeGroups]')
        const employeeGroups = [
            { uid: 1, text: 'Montage 1'},
            { uid: 2, text: 'Softwareenwickler' },
            { uid: 3, text: 'Projektteam' }
        ]
        return employeeGroups;
    } */
}

