
import store from '@/store/index';
import { defineComponent } from 'vue';
import { Project, Company, Employee } from '@/model';
import { List, ListItem, Searchbar, IconButton, OptionButtonGroup } from '@/ui/index';
import { EmployeeController, ProjectController, RightController, SessionController } from '@/controller/';

export default defineComponent({
    name: "ProjectList",
    components: { List, ListItem, Searchbar, IconButton, OptionButtonGroup },
    created(){

        this.sessionCompany = SessionController.sessionCompany
        /* this.sessionEmployee = SessionController.sessionEmployee */
        this.getEmployee(this.$route.params.id)
        this.handleGetProjects()
    },  
    data(){
        return{
            store,
            projects: [] as Array<any>,
            searchInput: "",
            sessionCompany: {} as Company,
            employee: {} as Employee,
            displayOptions: [
                {'label': 'Alle', 'value': 0},
                {'label': 'Aktive', 'value': 1},
                {'label': 'nicht Aktive', 'value': 2},
            ],
            displayMode: 0
        }
    },
    computed:{
        filteredProjectList() {
            if (this.projects) {
                let projectList : Project[] = []

                if (this.displayMode == 0) {
                    projectList = [...this.projects]
                } else if (this.displayMode == 1) {
                    projectList = [...this.activeProjects]
                } else if (this.displayMode == 2) {
                    projectList = [...this.inActiveProjects]
                }

                if (this.searchInput != "") {
                    projectList = projectList.filter((item:any) => {
                        if (item.title.toLowerCase().includes(this.searchInput.toLowerCase())) {
                            return item
                        }
                    });
                }
                return projectList;
            }
            return [];
        },
        activeProjects(): Project[] {
            return this.projects ? this.projects.filter(project => this.employee.activeProjects.find(aP => aP.uid == project.uid) != null) : []
        },
        inActiveProjects(): Project[] {
            return this.projects ? this.projects.filter(project => this.employee.activeProjects.find(aP => aP.uid == project.uid) == null) : []
        }
    },
    methods: {
        /* getProjectTitle(project: Project) {
            let title = project.numberExternal ? project.numberExternal : ''
            title += project.numberInternal ? (title ? ' / ' : '') + project.numberInternal : ''
            title += project.numberSerial ? (title ? ' / ' : '') + project.numberSerial : ''
            title += project.title ? (title ? ' ' : '') + project.title : ''
            return title
        },  */       
        handleOnChangeSearchInput(input : string) {
            this.searchInput = input;
        },
        async handleGetProjects() {
            this.projects = (await ProjectController.fetchProjects()).items;
        },
        handleOpenProject(project:Project) {
            this.$router.push('/projectdetail/' + project.uid)
        },
        handleEditProject(project:Project) {
            this.$router.push('project/edit/' + project.uid)
        },
        handleAddProject(project:Project) {
            this.addActiveProject(project)
        },
        handleRemoveProject(project:Project) { 
            this.removeActiveProject(project)
        },
        handleDisplayChange(value: any) {
            this.displayMode = value
        },
        onGoBack() {
            this.$router.go(-1);            
        },
        isProjectActive(project: Project) {
            //if (this.activeProjects) {
                return this.activeProjects.find(pr => pr.uid == project.uid) != null
            //} else {
            //    return false
            //}
        }, 
        checkRight(identifier:string) {
            return RightController.checkRight(identifier)
        },
            //database
        async getEmployee(uid:any) {
            const res = await EmployeeController.getEmployeeById(uid);
            if (!res.error) {
                this.employee = res.employee;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },        
        async addActiveProject(project: Project) {
            const res = await EmployeeController.addProject(this.employee.getUid(), project.getUid())
            if (res.employee) {
                //SessionController.setSessionEmployee(res.employee)
                this.employee = res.employee
            }
        },
        async removeActiveProject(project: Project) {
            const res = await EmployeeController.removeProject(this.employee.getUid(), project.getUid())
            if (res.employee) {
                //SessionController.setSessionEmployee(res.employee)
                this.employee = res.employee
            }
        }
    }
})
