
import { Button, Card, DataList, IconButton, Switch } from "@/ui/index";
import { defineComponent } from "vue";
import { EmployeegroupController } from "@/controller/";
import store from "@/store/index";
import { Employeegroup } from "@/model/index.ts";
import { DateTimeUtils, Converter } from "@/utils";

export default defineComponent({
    name: "EmployeeDetail",
    components: { Button, Card, DataList, IconButton, Switch },
    data() {
        return{
            store,
            employeegroup:{} as Employeegroup,
            timeTables: [] as Array<any>,
            lastActivities: [] as Array<any>
        }
    },
    computed:{
    },
    created() {
        if (this.$route.params.id) {
            this.getEmployeegroup(this.$route.params.id);
        }
    },
    methods:{
        handleGoBack() {
            this.$router.go(-1);
        },
        handleEdit() {
            this.$router.push("/administration/employeegroup/form/" + this.$route.params.id);
        },
        convertWorkTimeStamp(start:any, stop:any, totaltime:any) {
            let time = 0;
            if(start >0 && stop > 0) {
                time = stop -start;
            } else {
                time = totaltime;
            }
            var date = new Date(time * 1000);
            return date.getHours() + ":" + date.getMinutes()+ " h";
        },
        handleDeactivate() {
            //console.log("[TODO] handleDeactivate")
        },
        handleIconButtonClicked() {
            //console.log("[TODO] handleIconButtonClicked")
        },
        async getEmployeegroup(uid:any) {
            const res = await EmployeegroupController.getEmployeegroupById(uid);
            if (!res.error) {
                this.employeegroup = res.employeegroup;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleConfirmDeleteProject() {
            this.$confirm(this.$t("confirm.delete"), this.$t("confirm.deleteEmployeegroupText", { name: this.employeegroup.title }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete() },
                //onCancel: () => { console.log("onCancel"); }
           });
        },
        async handleDelete() {
            /*const res = await EmployeeController.deleteEmployee(this.employee.uid)
            if (!res.error) {
                this.$notify(this.$t("success.employeeDeleted"), { position: "top" });
                this.$router.push("/administration/employees");
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            } */
        },
        convertSecondsToHours(seconds : number){
            return Converter.convertSecondsToTime(seconds);
        },
        convertTimeStampToDate(tstamp:any) {
            return Converter.convertTimeStampToDate(tstamp);
        },          
    }
});
