
import store from "@/store/index";
import { WorkstationController, DepartmentController, MachineController, WorkareaController, WorkController } from '@/controller';
import { Workstation, Work } from '@/model'
import { IconButton, Button, SelectSingle, Card, Switch, Table, SelectSingleSearch } from '@/ui/index';
import { defineComponent } from 'vue';

export default defineComponent({
    name: "WorkstationstationForm",
    components: { IconButton, Button, SelectSingle, Card, Switch, Table, SelectSingleSearch },
    data() {
        return {
            store,
            editMode: false,
            workstation: {} as Workstation,
            form:{
                uid: 0,
                title: "",
                department: 0,
                workarea: 0,
                machine: 0,
                uuidWorkstation: '',
                nfcChipReader: false,
                presenceAllowed: false,
                workbreakAllowed: false,
                timeentryAllowed: false,
                works: [] as Array<number>
            },
            departments: [] as Array<any>,
            workareas: [] as Array<any>,
            machines: [] as Array<any>,
            works: [] as Array<Work>,
            worksTableData:{
                thFields: [ 
                    { title:this.$t("label.title"), property: "title", type: "string" }, 
                ],
                tbActions: [ 
                    { name: "delete", icon: store.getters.svgIcons.remove } 
                ]
            },     
            selectedWork: {
                uid: 0,
                title: '' 
            }
        }
    },
    computed:{
        getProfileImageSrc() {
            return "";
        },
        filteredWorks() {
            const workstation = this.workstation as Workstation
            return workstation.works.map((w: Work) => {
                return {
                    uid: w.uid,
                    title: w.title
                }
            })
        }
    },
    created() {
        if (this.$route.params.id && this.$route.params.id != "0" ) {
            this.editMode = true
            this.getWorkstation(this.$route.params.id)
        } else {
            this.workstation = Workstation.create();
            this.editMode = false
        }
        this.getDepartments()
        this.getWorkareas()
        this.getMachines()
        this.getWorks()
    },
    methods:{
        onGoBack() {
            this.$router.go(-1);
        },
        onSubmitPrevent(){
            if (this.editMode) {
                this.saveWorkstation();
            } else {
                this.createWorkstation();
            }
        },
        onTableActionClicked(action : string, item:any) {
            switch(action) {
                /*case "openDetail":
                    this.handleOpenDetailView(item.uid);
                    break*/
                case "delete":
                    this.removeWork(item);
                    break
            }
        },
        onSelectWork(item: any, id: number, title: string) {
            console.log('onSelectWork', item, id, title)
            this.selectedWork.uid = id
            this.selectedWork.title = title
        },
        onAddWork() {
            const work = this.works.find((w: Work) => w.uid == this.selectedWork.uid) as Work
            const idx = (this.workstation.works.length) ? this.workstation.works.findIndex((w: Work) => w.uid == work.uid) : -1
            if (idx == -1) this.workstation.works.push(work)
        },
        setFormData() {
            this.form.uid = this.workstation.uid
            this.form.title = this.workstation.title
            this.form.department = this.workstation.department
            this.form.workarea = this.workstation.workarea
            //this.form.machine = this.workstation.machine
            this.form.uuidWorkstation = this.workstation.uuidWorkstation
            this.form.nfcChipReader = this.workstation.nfcChipReader
            this.form.presenceAllowed = this.workstation.presenceAllowed
            this.form.workbreakAllowed = this.workstation.workbreakAllowed
            this.form.timeentryAllowed = this.workstation.timeentryAllowed
        },
        removeWork(item: any) {
            const idx = this.workstation.works.findIndex((w: Work) => w.uid == item.uid)
            if (idx != -1) this.workstation.works.splice(idx, 1)
        },
        async getWorkstation(uid:any) {
            const res = await WorkstationController.getWorkstationById(uid);
            if (!res.error) {
                //console.log('res.owkrstation', res.workstation)
                this.workstation = res.workstation
                this.setFormData()
                this.form = res.workstation
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getDepartments() {
            const res = await DepartmentController.fetchDepartments();
            if(!res.error){
                this.departments = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }        },
        async getMachines() {
            const res = await MachineController.fetchMachines();
            if(!res.error){
                this.machines = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getWorks() {
            const res = await WorkController.fetchWorks();
            if(!res.error){
                this.works = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }        
        },
        async getWorkareas() {
            const res = await WorkareaController.fetchWorkareas();
            if(!res.error){
                this.workareas = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }        
        },
        async createWorkstation() {
            this.form.works = this.workstation.works.map((w: Work) => w.uid)
            const res = await WorkstationController.createWorkstation(this.form);
            if (!res.error) {
                this.$notify(this.$t("success.workstationCreated"), { position: "top" });
                this.$router.push("/administration/workstations");
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async saveWorkstation() {
            this.form.works = this.workstation.works.map((w: Work) => w.uid)
            const res = await WorkstationController.updateWorkstation(this.form);
            if (!res.error) {
                this.form = res.workstation;
                this.$notify(this.$t("success.workstationUpdated"), { position: "top" });
                this.$router.push("/administration/workstations");
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
