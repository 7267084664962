
import { defineComponent } from 'vue';
import { Button, IconButton, List, ListItem } from '@/ui/index';
import {  SessionController } from '@/controller/';
import { User, Employee, Company } from '@/model';
import store from '@/store/index';

export default defineComponent({
    name: "Profile",
    components: { Button, IconButton, List, ListItem  },
    mounted(){
        this.getSessionUser();
        this.getSessionCompany();
    },
    data(){
        return{
            store,
            sessionUser: {} as User,
            sessionEmployee: {} as Employee,
            sessionCompany: {} as Company
        }
    },
    methods: {
        getSessionUser() {
            this.sessionUser = SessionController.sessionUser;
            this.sessionEmployee = SessionController.sessionEmployee;
        },
        async getSessionCompany() {
            this.sessionCompany = await SessionController.getSessionCompany();
        },
        handleShowTimetables() {
            //console.log("TODO: Show timeTables")
        }
    },
})
