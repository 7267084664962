import axios from "axios";
import { Api } from "../api";
import { ObjectStorage, Machine } from "@/model/index";
import AxiosErrorService from "@/service/AxiosErrorService";

export default class MachineRepository {
    static machines = new ObjectStorage([]); 

    static async fetchMachines() {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_MACHINES_ACTION)
        .then((response:any) => {
         //console.log('fetchMachines res', response)
            this.machines = new ObjectStorage(Machine.createMachinesFromResponse(response));
            result = this.machines;
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async getMachineById(uid:any) {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_MACHINE_BY_ID_ACTION + uid)
        .then((response:any) => {
            result = {
                machine: Machine.createMachineFromResponse(response.data.unit)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async createMachine(postData : any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.CREATE_MACHINE_ACTION, postData)
        .then((response:any) => {
            //console.log("createMachine res", response)
            result = {
                machine: Machine.createMachineFromResponse(response.data.unit)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async updateMachine(postData : string) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.UPDATE_MACHINE_ACTION, postData)
        .then((response:any) => {
            //console.log("updateMachine res", response)
            result = {
                machine: Machine.createMachineFromResponse(response.data.unit)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async deleteMachine(postData:any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.DELETE_MACHINE_ACTION, postData)
        .then((response:any) => {
            //console.log("deleteWorkarea res", response)
            result = {
                machine: Machine.createMachineFromResponse(response.data.unit)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result
    }
}
