
import { defineComponent } from 'vue';
import { Sidebar, TimiHeader, AdminHeader, DefaultHeader, TrackingViewNav, WorkspaceHeader, WorkspaceFooter }  from './components';
import { SessionController, LoginController, WorkstationController } from '@/controller/';
import { User, Employee, Workstation } from "@/model/index.ts";
import store from '@/store/index';
import { useEventBus } from '@vueuse/core'
import { appEventKey } from '@/bus/eventkey'

export default defineComponent({
    name: "Layout",
    components: { Sidebar, AdminHeader, DefaultHeader, TimiHeader, TrackingViewNav, WorkspaceHeader, WorkspaceFooter },
    created() {
        //console.log('layout created')
        //this.checkSessionUser()
        this.eventBus.on((e) => {
            if (e === 'onUser') this.onUserChanged()
            if (e === 'onWorkstation') this.onWorkstation();
        });         
    },
    mounted() {
        //console.log('layout mounted')
    },
    computed: {
        key() : string {
            return this.$route.fullPath;
        },
        adminRouteName() : any {
            if(this.$route.name){
                switch (this.$route.name) {
                    case "Dashboard":
                        return this.$t('route.dashboard');
                    case "Evaluation":
                        return this.$t('route.evaluation');                        
                    case "EmployeeList":
                        return this.$t('route.employee');
                    case "EmployeegroupList":
                        return this.$t('route.employeegroup');
                    case "EmployeeDetail":
                        return this.$t('route.employee');
                    case "EmployeegroupDetail":
                        return this.$t('route.employeegroup');
                    case "EmployeegroupForm":
                        if(this.$route.params.id == "0"){
                            return this.$t('route.createEmployeegroup');
                        } else {
                            return this.$t('route.editEmployeegroup');
                        }                        
                    case "EmployeeForm":
                        if(this.$route.params.id == "0"){
                            return this.$t('route.createEmployee');
                        } else {
                            return this.$t('route.editEmployee');
                        }
                    case "EmployeeProjects":
                        return this.$t('route.activeProjectsEmployee');
                    case "ProjectList":
                        return this.$t('route.projects');
                    case "ProjectDetail":
                        return this.$t('route.project');
                    case "ProjectForm":
                        if(this.$route.params.id == "0"){
                            return this.$t('route.createProject');
                        } else {
                            return this.$t('route.editProject');
                        }
                    case "WorkList":
                        return this.$t('route.works');
                    case "WorkDetail":
                        return this.$t('route.work');
                    case "WorkForm":
                        if(this.$route.params.id == "0"){
                            return this.$t('route.createWork');
                        } else {
                            return this.$t('route.editWork');
                        }
                    case "ClientList":
                        return this.$t('route.clients');
    
                    case "ClientDetail":
                        return this.$t('route.client');
                    case "ClientForm":
                        if(this.$route.params.id == "0"){
                            return this.$t('route.createClient');
                        } else {
                            return this.$t('route.editClient');
                        }
                    case "DepartmentList":
                        return this.$t('route.departments');
                    case "DepartmentDetail":
                        return this.$t('route.department');
                    case "DepartmentForm":
                        if(this.$route.params.id == "0"){
                            return this.$t('route.createDepartment');
                        } else {
                            return this.$t('route.editDepartment');
                        }
                    case "MachineList":
                        return this.$t('route.machines');
    
                    case "MachineDetail":
                        return this.$t('route.machine');
                    case "MachineForm":
                        if(this.$route.params.id == "0"){
                            return this.$t('route.createMachine');
                        } else {
                            return this.$t('route.editMachine');
                        }
                    case "WorkstationList":
                        return this.$t('route.workstations');
    
                    case "WorkstationDetail":
                        return this.$t('route.workstation');
                    case "WorkstationForm":
                        if(this.$route.params.id == "0"){
                            return this.$t('route.createWorkstation');
                        } else {
                            return this.$t('route.editWorkstation');
                        }
                    case "WorkareaList":
                        return this.$t('route.workareas');
    
                    case "WorkareaForm":
                        if(this.$route.params.id == "0"){
                            return this.$t('route.createWorkarea');
                        } else {
                            return this.$t('route.editWorkarea');
                        }
                    case "VehicleList":
                        return this.$t('route.vehicles');
                    case "WorkingTimeSchemeList":
                        return this.$t('route.workingTimeScheme');
                    case "Personalization":
                        return this.$t('route.personalization');
                    case "CompanySettings":
                        return this.$t('route.companySettings');
                }
                const routeName : string = (this.$route.name as string)
                if (routeName.includes('Dashboard')) {
                    return this.$t('route.dashboard');
                }
            }
            return "No route name"
        },
        checkLayout() {
            if(this.key == '/login' || this.key=='/forgotpassword' || this.key.includes('/resetpassword')) {
                return 'login'
            } else if(this.key.includes('/administration/')) {
                return 'admin'
            } else if(this.key.includes('/timeentryform/')) {
                return 'timeentryform'
            } else if(this.key.includes('/overview')) {
                return 'greetuser'
            } else if(this.key.includes('/workspace')) {
                return 'workspace'
            }
            return 'default'
        },
        checkIsTimeEntryForm() {
            if(this.key.includes('/timeentryform/')) {
                return false
            }
            return true
        },
    },
    data(){
        return{
            store,
            sessionUser: {} as User,
            sessionEmployee: {} as Employee,
            localWorkstation: {} as Workstation,
            notifyCount: 0,
            eventBus: useEventBus(appEventKey),
        }
    },
    methods:{
        /*testNotify() {
           this.$notify('Und hier kommt der Fehler! ' + this.notifyCount, {
            position: 'bottom',
            type: 'error'
           });
           this.notifyCount++;
        },
        testConfirm() {
           this.$confirm('Das ist der Titel', 'und das die Message', {
            onConfirm: () => { console.log('onConfirm'); },
            onCancel: () => { console.log('onCancel'); }
           });
        },*/
        onUserChanged() {
            this.sessionUser = SessionController.sessionUser
            this.sessionEmployee = SessionController.sessionEmployee
        },
        onWorkstation() {
            this.localWorkstation = WorkstationController.localWorkstation
        },
        getHeaderTitle(routerkey:any){
            switch (routerkey) {
                case '/overview':
                    return 'Hallo '+ this.sessionEmployee.firstName + '!';
                case '/projects':
                    return this.$t('route.projectlist');
                case '/profile':
                    return this.$t('route.profile');
                case '/timeentryform':
                    return this.$t('route.timeentryform');
                default: {
                    return 'Hallo '+ this.sessionEmployee.firstName + '!';
                }
            }  
        },
        getSessionUser() {
            //const { user, employee } = await SessionController.getSessionUser();
            this.sessionUser = SessionController.sessionUser
            this.sessionEmployee = SessionController.sessionEmployee
        },
        async logoutUser() {
            LoginController.logoutUser();
            this.$router.push('/login')
        },
        /*checkSessionUser() {
            if (SessionController.loginRequired) {
                    //todo needs better checking
                this.sessionUser = SessionController.sessionUser
                this.sessionEmployee = SessionController.sessionEmployee
                if (!this.sessionUser.uid) {
                    console.log('Layout:checkSessionUser')
                    setTimeout(() => { console.log('no sessionuser get'); this.checkSessionUser() }, 200)
                    return
                }
            }
        } */
    } 
})
