import axios from "axios";
import { Api } from "../api";
import { ObjectStorage, Department } from "@/model/index";
import AxiosErrorService from "@/service/AxiosErrorService";

export default class DepartmentRepository {
    static departments = new ObjectStorage([]); 

    static async fetchDepartments() {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_DEPARTMENTS_ACTION)
        .then((response:any) => {
         //console.log('fetchDepartments res', response)
            this.departments = new ObjectStorage(Department.createDepartmentsFromResponse(response));
            result = this.departments;
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }
    static async getDepartmentById(uid:any){
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_DEPARTMENT_BY_ID_ACTION + uid)
        .then((response:any) => {
            result = {
                department: Department.createDepartmentFromResponse(response.data.department)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async createDepartment(postData : any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.CREATE_DEPARTMENT_ACTION, postData)
        .then((response:any) => {
            //console.log("createDepartment res", response)
            result = {
                department: Department.createDepartmentFromResponse(response.data.department)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async updateDepartment(postData : string) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.UPDATE_DEPARTMENT_ACTION, postData)
        .then((response:any) => {
            //console.log("createDepartment res", response)
            result = {
                department: Department.createDepartmentFromResponse(response.data.department)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async deleteDepartment(postData:any){
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.DELETE_DEPARTMENT_ACTION, postData)
        .then((response:any) => {
            //console.log("deleteDepartment res", response)
            result = {
                department: Department.createDepartmentFromResponse(response.data.department)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }
}
