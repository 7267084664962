import axios from "axios"
import { Api } from "../api"
import { CompanySettings } from '@/model'
import AxiosErrorService from "@/service/AxiosErrorService"

const CompanyRepository = {
    async getCompany(){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GET_COMPANY_ACTION)
            //console.log('getCompany res', res)
            return res.data.company;
        } catch(e){
            console.log('getCompany error:', e);
            return {}
        }
    },

    async postSettings(postData : string) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.POST_COMPANYSETTINGS_ACTION, postData)
        .then((response:any) => {
            //console.log("postSettings res", response)
            result = {
                companySettings: CompanySettings.createCompanySettingsFromResponse(response.data.companysettings)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }    
}
export default CompanyRepository;